var React = require('react');
var {connect} = require('react-redux');
var farmingUnitManager = require('farmingUnitManager');
var moment = require('moment');
var distributionActions = require('distributionActions');
var CustomMap = require('CustomMap');
var { withTranslation } = require('react-i18next');
var {latestKeyfromDict} = require("@managers/helpers");
class MapContainer extends React.Component {

  constructor(props) {
    super(props);

    this.onPolygonClick = this.onPolygonClick.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.screenResize = this.screenResize.bind(this);
    this.onLayerSelection = this.onLayerSelection.bind(this);
    this.onNDVILayerSelection = this.onNDVILayerSelection.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedField) {
      this.updateMap(this.props.selectedField);
    }

    window.addEventListener("resize", this.screenResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.screenResize);
  }

  componentDidUpdate(nextProps, nextState) {
    if ((nextProps.loadHistoryStatus.status == "success") || (nextProps.farmingHistoryStatus.status == "success") || (this.props.selectedField && !nextProps.selectedField) ||
        (nextProps.selectedField && this.props.selectedField && (JSON.stringify(nextProps.selectedField) != JSON.stringify(this.props.selectedField)))) {
      this.updateMap(this.props.selectedField);
    }
  }

  screenResize() {
    this.setState({});
  }

  updateMap(field) {

    if (this.props.selectedFarmingUnit) {
      var shouldPresentFarmingUnitNdvi = false
      var selected_field_index = field ? field?.id : "";

      if (this.custom_map) {
        let fieldsState = this.props.archiveMode ? 'archive' : 'active'
        var all_areas = farmingUnitManager.all_farming_unit_areas(this.props.selectedFarmingUnit, selected_field_index, fieldsState);

        // display markers only when we display all sensors
        var all_markers = {};
        if (this.props.selectedFarmingUnit && farmingUnitManager.shouldDisplayAllSensors(this.props.selectedFarmingUnit)) {
          all_markers = farmingUnitManager.all_farming_unit_markers(this.props.selectedFarmingUnit, selected_field_index, fieldsState);
        }

        let ndviPolygons = [{}];

        let selectedNDVITiles = {};
        if (field !== undefined) {
          if ("ndvi" in field.historical_data) {
            selectedNDVITiles = field.historical_data["ndvi"]["values"];
          }
        }

        // loading all NDVI maps per farmung unit
        for (let currentFieldIndex in this.props.selectedFarmingUnit.fields) {

          let currentField = this.props.selectedFarmingUnit.fields[currentFieldIndex];

          let isSelectedField = currentField.id === field?.id;

          let currentKey = "ndvi";
          if (currentKey in currentField.historical_data) {

            let currentNDVITiles = currentField.historical_data["ndvi"]["values"];
            var fieldLastCaptureDate = latestKeyfromDict(currentNDVITiles)

            if (this.props.selectedFarmingUnit.historical_data["ndvi"] && fieldLastCaptureDate in this.props.selectedFarmingUnit.historical_data["ndvi"]["values"]) {
              shouldPresentFarmingUnitNdvi = true
            }
            for (let key in currentNDVITiles) {

              if (key in selectedNDVITiles) {
                let currentValue = currentNDVITiles[key];

                if (currentValue["image_path"]) {

                  currentValue["isSelectedField"] = isSelectedField;
                  if (isSelectedField && this.props.selectedFarmingUnit.historical_data["ndvi"] && this.props.selectedFarmingUnit.historical_data["ndvi"]["values"][key]) {
                    currentValue["farming_details"] = this.props.selectedFarmingUnit.historical_data["ndvi"]["values"][key]
                  }

                } else {
                  currentValue = {
                    'tile': currentValue,
                    'mean': undefined,
                    'cloudiness': undefined,
                    'useful_area': undefined,
                    'source': undefined
                  };
                }

                if (key in ndviPolygons[0]) {
                  ndviPolygons[0][key].push(currentValue);
                } else {
                  ndviPolygons[0][key] = [currentValue];
                }
              }

            }
          }
        }

        this.custom_map.invalidate(all_areas, all_markers, ndviPolygons, false, shouldPresentFarmingUnitNdvi);
      }
    }
  }

  onPolygonClick(polygon_id) {

    var currentSelectedField = this.props.selectedFarmingUnit.fields[polygon_id];

    this.props.setSelectedEntities(null, null, currentSelectedField, null);
  }

  onMarkerClick(polygon_id) {

    var currentSelectedField = this.props.selectedFarmingUnit.fields[polygon_id];

    this.props.setSelectedEntities(null, null, currentSelectedField, null);
  }

  onLayerSelection(layerName) {
    mixpanel.track("Map Layer Change", {'Layer Name':layerName});
  }

  onNDVILayerSelection(layerName) {
    let date = moment.unix(layerName).format('DD-MM-YYYY');
    mixpanel.track("NDVI Layer Change", {'Layer Name':layerName});
  }


  render() {
    const { t } = this.props;
    var is_admin = (this.props.selectedFarmingUnit && this.props.selectedFarmingUnit.role == "admin");

    let mapHeight = "664";
    if (window.innerWidth < 768) {
      mapHeight = "400";
    }


    return (
      <div className="w-100 pb-lg-5 pb-0" style={{"backgroundColor":"#F6F8FA", "borderRight":"1px solid #DCE0E8", "borderLeft":"1px solid #DCE0E8", "borderBottom":"1px solid #DCE0E8","borderBottomRightRadius":"8px","borderBottomLeftRadius":"8px", zIndex:"0"}}>
        <div className="d-lg-none text-grey45 text-size-16 pb-3 font-weight-semi-bold pl-4">
          {t('map_view')}
        </div>
        <div className="pl-3 pr-3" style={{paddingTop:"40px"}}>
          <CustomMap mapId="dashboard"
                     showEditToolBar={false}
                     onRef={ref => (this.custom_map = ref)}
                     message=""
                     onPolygonClick={this.onPolygonClick}
                     onMarkerClick={this.onMarkerClick}
                     onLayerSelection={this.onLayerSelection}
                     onNDVILayerSelection={this.onNDVILayerSelection}
                     map_height={mapHeight}
                     shouldPresentStressMenu={true}
                     shouldPresentFilePicker={false}
                     />
        </div>
      </div>
    );
  }
};

const mapStateToProps = function(state){
  return {
    selectedField: state.distribution_data.selected_entities.field,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    loadHistoryStatus: state.distribution_data.field_load_history,
    farmingHistoryStatus: state.distribution_data.farming_unit_load_history
  }
}

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities
}

module.exports = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(MapContainer));
