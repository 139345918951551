var React = require('react');
const {useState} = require("react");
var ContextMenu = require('ContextMenu');
require('./Sensor.scss');
var { useTranslation, withTranslation} = require('react-i18next');
const {connect} = require("react-redux");

const Sensor = ({type, sub_type, valves, transmitter_id, coordinates, m3_per_pulse,manufacturer, ignore_in_calculations, paused, should_consider_as_wfr, onEdit, onLocate, onIgnore, onPaused, onDelete, onConsiderAsWFR,name, userData, offset}) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState(false);

    const typeOptions = {
        motorola: 'Motorola',
        ndrip: 'N-Drip Flow Meter',
        galcon: 'Galcon',
        aquastride: 'Aquastride',
        "tal-gil": "Tal Gil"
    }

    const menuOptions = type === 'piezometer' ? [{
        title: t("edit"),
        icon: require('images/icons/edit_field/edit_icon.svg'),
        func: onEdit
    }, {
        title: t("locate_coordinates"),
        icon: require('images/icons/edit_field/navigate_icon.svg'),
        func: onLocate
    }, {
        type: "divider"
    },
        {
            title: type == "wp" ? t("ignore_sensor_during_calculations"): t("ignore_sensor_during_calculations_and_hide"),
            type: "checkBox",
            func: onIgnore,
            checkBoxOn: ignore_in_calculations
        }, {
            title: t("stop_data_collection"),
            type: "checkBox",
            func: onPaused,
            checkBoxOn: paused
        }, {
            title: t("consider_as_flow_meter"),
            type: "checkBox",
            func: onConsiderAsWFR,
            checkBoxOn: should_consider_as_wfr
        }, {
            type: "divider"
        }, {
            title: t("delete"),
            icon: require('images/icons/trash.svg'),
            func: onDelete
        }
    ] : [{
        title: t("edit"),
        icon: require('images/icons/edit_field/edit_icon.svg'),
        func: onEdit
    }, {
        title: t("locate_coordinates"),
        icon: require('images/icons/edit_field/navigate_icon.svg'),
        func: onLocate
    }, {
        type: "divider"
    },
        {
            title: type == "wp" ? t("ignore_sensor_during_calculations"): t("ignore_sensor_during_calculations_and_hide"),
            type: "checkBox",
            func: onIgnore,
            checkBoxOn: ignore_in_calculations
        }, {
            title: t("stop_data_collection"),
            type: "checkBox",
            func: onPaused,
            checkBoxOn: paused
        }, {
            type: "divider"
        }, {
            title: t("delete"),
            icon: require('images/icons/trash.svg'),
            func: onDelete,
            textColor:"#F66B7A"
        }
    ]

    const middleColTitle = valves?.length ? t("valves") : type === "pulse" ? t("water_meter_units") :  type === 'piezometer' ? `${t("offset")} (${userData?.length})` : '';
    const middleColContent = valves?.length ? (<div className='d-flex flex-wrap'>
     {valves.map(valve => <div key={valve} className='text-size-14 font-weight-semi-bold bg-grey96' style={{lineHeight: 1, padding: '2px 12px 2px', margin: '0 4px 4px 0', borderRadius: '500px',height:"22px"}}>{valve}</div>)}
    </div>): middleColTitle === t("water_meter_units") ? <div style={{lineHeight: 1,}}>{m3_per_pulse}</div>  : middleColTitle === `${t("offset")} (${userData?.length})` ? <div style={{lineHeight: 1}}>{offset || 0}</div> : null

    return (<div className="d-flex mb-4" >
        <div className=" d-flex justify-content-between flex-grow-1 card-body card flex-row"
             style={{padding: '12px 16px'}}>
            <div className='text-grey45' style={{width: '120px', paddingRight: '12px'}}>

                <label style={{lineHeight: 1}} className='font-weight-semi-bold mb-0 text-size-14 lh-1'>{t("type")}</label>
                <div style={{lineHeight: 1}}>{type === 'wp' ? "N-Drip Tensiometer" : type === 'pulse' ? "N-Drip Pulse" : type === 'piezometer' ? "N-Drip Piezometer" : typeOptions[manufacturer] + (sub_type || '')}</div>
            </div>
            <div className='text-grey45' style={{width: '120px', paddingRight: '12px'}}>
                <label style={{lineHeight: 1}} className='font-weight-semi-bold mb-0 text-size-14 lh-1'>{t('transmitter_id')}</label>
                <div style={{lineHeight: 1}}>{transmitter_id}</div>
            </div>
            <div className='text-grey45' style={{width: '120px', paddingRight: '12px'}}>
                <label style={{lineHeight: 1}} className='font-weight-semi-bold mb-0 text-size-14 lh-1'>{t('name')}</label>
                <div style={{lineHeight: 1}}>{name}</div>
            </div>
            <div className='text-grey45' style={{width: '120px', marginRight: '80px'}}>
                <label
                style={{lineHeight: 1}}
                className='font-weight-semi-bold mb-0 text-size-14 lh-1'>{middleColTitle}</label>
                <div style={{lineHeight: 1}}>{middleColContent}</div>
            </div>
            <div className='text-grey45 d-none d-sm-block' style={{width: '146px'}}>
                <label style={{lineHeight: "140%"}} className='font-weight-semi-bold mb-0 text-size-14 lh-1'>{t('location')}</label>
                <div style={{lineHeight: "120%",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}><text className="text-size-14 font-weight-semi-bold">Lat. &nbsp;&nbsp;&nbsp;</text>{coordinates.latitude || ''}</div>
                <div style={{lineHeight: "120%",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}><text className="text-size-14 font-weight-semi-bold">Long. </text>{coordinates.longitude || ''}</div>
            </div>
        </div>

        <div className='d-flex align-items-center justify-content-between position-relative'>
            {showMenu && <ContextMenu options={menuOptions} onToggle={setShowMenu}/>}
            <button onClick={(e) => { setShowMenu(!showMenu); e.stopPropagation() }} type='button' className={`btn d-flex justify-content-center align-items-center sensorMenuBtn ${showMenu ? 'active' : ''}`}>
                <img src={require('images/icons/three-dots-horizontal.svg')} />
            </button>
        </div>
    </div>);
};

const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data,
    }
}

module.exports = connect(mapStateToProps)(withTranslation()(Sensor));