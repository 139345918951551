var React = require('react');
var ReactDatetime = require('react-datetime');
var { useTranslation } = require('react-i18next');

// inputProps => { id, required, disabled }
const DayPickerInput = ({ value, onChange, dateFormat = 'YYYY-MM-DD', dayPickerProps, inputProps }) => {
  const { className, ...otherInputProps } = inputProps;
  const { t } = useTranslation();

  return (
    <ReactDatetime
      value={value}
      onChange={onChange}
      dateFormat={dateFormat}
      timeFormat={false}
      closeOnSelect
      {...dayPickerProps}
      inputProps={{
        className: `form-control ${className || ''}`,
        type: "text",
        placeholder: t('choose_date'),
        style: {width: '100%'},
        ...otherInputProps
      }}
    />
  )
}

module.exports = DayPickerInput;