var React = require('react');
const {connect} = require("react-redux");
var FieldsSelector = require('FieldsSelector');
const {useState} = require("react");
var ArchiveFieldsSelector = require('ArchiveFieldsSelector');
const {useTranslation} = require("react-i18next");
var ToggleSwitch = require("ToggleSwitch")
var {SelectMenu} = require("SelectMenu")
var distributionActions = require('distributionActions');


const FieldsContainer = (props) => {

    let {archiveMode, setArchiveMode, set_field_state} = props
    const [sortType, setSortType] = useState("deficit")
    const {t} = useTranslation();


    const onToggleClick = () => {
        setArchiveMode()
    }

    React.useEffect(() => {
        let fieldState = archiveMode ? "archive" : "active"
        let sortOption = archiveMode ? "alphabetic" : "deficit" 
        setSortType(sortOption)
        set_field_state(fieldState)
    }, [archiveMode, set_field_state])

    const handleSortBy = (sortBy) => {
        setSortType(sortBy)
    }

    // sort menu options for generic Select Menu
    const archiveSortOptions = [
        {title: t('alphabetic'), value: 'alphabetic'},
        {title: t('kc_harvest_date'), value: 'date'}
    ]

    // sort menu options for generic Select Menu
    const activeSortOptions = [
        {title: t("soil_water_deficit"), value: 'deficit'},
        {title: t('alphabetic'), value: 'alphabetic'},
        {title: t('cluster'),value: 'cluster'}
    ]

    return (
        <div className="" style={{"width": "355px", height: "88%", "position": "fixed"}}>
            <div className="container-fluid p-0 bg-white" style={{
                boxSizing: "border-box",
                borderRight: "1px solid #DCE0E8",
            }}>
                <div className="row m-0 pr-3 pt-3 pb-3">
                    <div className="col text-grey45 font-weight-bold text-size-24" style={{maxHeight:"36px"}}>
                        {t('dashboard_my_fields')}
                    </div>
                    <ToggleSwitch initialValue={!archiveMode} onToggleClick={onToggleClick} toggleTitle={"show_archived"} isLabelLeft={false} toggleSize={"small"}/>
                </div>

                <div className="d-flex align-items-center justify-content-end text-size-14 text-grey45"
                     style={{gap: "18px", padding: "0px 16px 10px"}}>
                    {t("sort_by")}<div style={{width: "124px"}}><SelectMenu options={archiveMode ? archiveSortOptions : activeSortOptions}
                                                                      onChange={(sortBy) => handleSortBy(sortBy)} curValue={sortType}
                                                                      sortBox={"sortBox"}/></div>
                </div>
            </div>
            {archiveMode ? <ArchiveFieldsSelector setShowActive={onToggleClick} sortBy={sortType}/> : <FieldsSelector onCreateNewField={props.onCreateNewField} sortBy={sortType}/>}
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
        loadHistoryStatus: state.distribution_data.farming_unit_load_history,
        selectedField: state.distribution_data.selected_entities.field,
    }
}

const mapDispatchToProps = {
    setSelectedEntities: distributionActions.selected_entities,
    getFarmingUnitHistory: distributionActions.get_farming_units_history,
    set_field_state: distributionActions.set_field_state,

}

module.exports = connect(mapStateToProps, mapDispatchToProps)(FieldsContainer);