var React = require('react');
var {connect} = require('react-redux');
var fieldManager = require('fieldManager');
var weatherManager = require('weatherManager');
var {upper, latestObjectfromDict} = require('helpers');
var moment = require('moment');
var {withTranslation} = require('react-i18next');
var {unitDisplayName, convert} = require('unitsManager');
var {get_configuration_config} = require('remoteConfigurationsManager');
var IrrigationMeterDetailsContainer = require('IrrigationMeterDetailsContainer')
var MainCSS = require('mainCSS');

class ExtendedFieldDetails extends React.Component {

    constructor(props) {
        super(props);
        this.calculateArea = this.calculateArea.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    calculateArea(areas) {
        // calculate area
        let fieldArea = fieldManager.getFieldArea(areas);
        let fieldAreaConverted = convert(fieldArea, "dunam", this.props.userData.area, "area");
        return fieldAreaConverted;
    }

    render() {

        const {t} = this.props;
        let cropTitle = "";
        var lastET0Value = "0";
        var lastKC = "0";
        let expirationDate = "";
        let soilType = "";
        let fieldArea = "";
        let accumulatedGDD = "";
        let fert_height = "";
        let fert_volume = "";
        let leaf_size = undefined;
        // let petiole = undefined;
        let days_from_sowing = undefined;
        let alert = <div></div>;

        if (this.props.selectedField) {
            if (this.props.selectedField.geo_data.area_size === undefined) {
                fieldArea = this.calculateArea(this.props.selectedField.geo_data.areas);
            } else {
                fieldArea = this.props.selectedField.geo_data.area_size;
            }

            if (this.props.selectedField.crop_data.crop_variety != '') {
                cropTitle = upper(t(this.props.selectedField.crop_data.crop_type)) + "/" + upper(this.props.selectedField.crop_data.crop_variety);
            } else {
                cropTitle = upper(t(this.props.selectedField.crop_data.crop_type));
            }

            if ('et0' in this.props.selectedField.historical_data) {
                let et0Statistics = weatherManager.weatherDataStatistics(this.props.selectedField.historical_data.et0.values, this.props.selectedField.geo_data.time_zone);

                let startOfDay = moment().tz(this.props.selectedField.geo_data.time_zone).startOf('day').unix();

                // in case we dont have today value, we will show yesterdays
                let yesterdayStartOfDay = moment().tz(this.props.selectedField.geo_data.time_zone).startOf('day').subtract(1,"d").unix();

                if (startOfDay in et0Statistics) {
                    // Taking the last value of the day
                    lastET0Value = convert(parseFloat(et0Statistics[startOfDay]["lastValue"]).toFixed(2),"mm",this.props.selectedField.historical_data.etc?.units,"length");
                }
                else if (yesterdayStartOfDay in et0Statistics) {
                    lastET0Value =convert(parseFloat(et0Statistics[yesterdayStartOfDay]["lastValue"]).toFixed(2),"mm",this.props.selectedField.historical_data.etc?.units,"length")
                }

                var lastKCResult = fieldManager.last_kc(this.props.selectedField);
                lastKC = parseFloat(lastKCResult[0]).toFixed(2);
            }

            expirationDate = moment.unix(this.props.selectedField.contract_data.expiration_date).format("MMM Do YYYY");

            soilType = this.props.selectedField.soil_type;

            if (this.props.selectedField.historical_data.accu_gdd) {
                accumulatedGDD = latestObjectfromDict(this.props.selectedField.historical_data.accu_gdd.values) + " " + unitDisplayName(this.props.selectedField.historical_data.accu_gdd.units);
            }

            if (this.props.selectedField.historical_data.plant_height && (Object.keys(this.props.selectedField.historical_data.plant_height.values).length > 0)) {
                leaf_size = parseFloat(latestObjectfromDict(this.props.selectedField.historical_data.plant_height.values)).toFixed(2) + " " + unitDisplayName(this.props.selectedField.historical_data.plant_height.units);
            }
            if (get_configuration_config(this.props.configuration_data,"Fert tank height", this.props.selectedFarmingUnit, this.props.selectedDistribution) && this.props.selectedField.historical_data.fert_height && (Object.keys(this.props.selectedField.historical_data.fert_height.values).length > 0)) {
                //TODO: this is hard codded to cm, as it a POC and the algorithm calculate for cm only
                fert_height = parseFloat(latestObjectfromDict(this.props.selectedField.historical_data.fert_height.values)).toFixed(2) + " cm";
            }
            if (get_configuration_config(this.props.configuration_data,"Fert tank height", this.props.selectedFarmingUnit, this.props.selectedDistribution) && this.props.selectedField.historical_data.fert_volume && (Object.keys(this.props.selectedField.historical_data.fert_volume.values).length > 0)) {
                //TODO: this is hard codded to cm, as it a POC and the algorithm calculate for cm only
                fert_volume = parseFloat(latestObjectfromDict(this.props.selectedField.historical_data.fert_volume.values)).toFixed(2) + " L";
            }

            // if (this.props.selectedField.historical_data.petiole && (Object.keys(this.props.selectedField.historical_data.petiole.values).length > 0)) {
            //     petiole = parseFloat(latestObjectfromDict(this.props.selectedField.historical_data.petiole.values)).toFixed(2) + " ppm";
            // }
            if (this.props.selectedField.crop_data.sow_date) {
                if (moment().diff(moment(this.props.selectedField.crop_data.sow_date), "days") <= 0) {
                    days_from_sowing = 0;
                } else {
                    days_from_sowing = moment().diff(moment(this.props.selectedField.crop_data.sow_date), "days");
                }
            }
        }

        let divsStyle = this.props.field_state === "active" ? "text-grey45 text-truncate text-size-14" : "text-grey71 text-truncate text-size-14"
        let spanStyle = this.props.field_state === "active" ? "text-blue-secondary bg-grey96 font-weight-bold " : "text-grey71 font-weight-bold"
        let paddingStyle = this.props.field_state === "active" ? "pl-4 pr-4" : "pl-5 pr-5"
        return (

            <div className="d-flex flex-row bg-white w-100 fieldDetails fieldDetailsLargeScreens" style={{ 'borderTopLeftRadius':'15px', 'borderTopRightRadius':'15px', "lineHeight":"40px"}}>
                <div className="d-flex align-items-center IrrigationMeterDetailsContainer" style={{"borderLeft":"1px solid #DCE0E8","borderBottom":"0.5px solid #DCE0E8","borderTop":"0.5px solid #DCE0E8", borderTopLeftRadius: "14px"}}><IrrigationMeterDetailsContainer/></div>
                <div className="d-flex justify-content-around flex-wrap w-100 FieldDetailsContainer" style={{"borderRight": "1px solid #DCE0E8", "borderTop": "0.5px solid #DCE0E8"}}>
                    <div className={"text-center flex-grow-1 " + paddingStyle} style={{"borderBottom": "0.5px solid #DCE0E8","borderLeft": "0.5px solid #DCE0E8"}}>
                        <div className={divsStyle}>{t('crop_type')} <span
                            className={"p-1 pr-2 pl-2 ml-1 " + spanStyle} style={{"borderRadius": "5px"}}>{cropTitle}</span>
                        </div>
                    </div>
                    <div className={"text-center flex-grow-1 " + paddingStyle} style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8"}}>
                        <div className={divsStyle}>{t('area')} <span
                            className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                            style={{"borderRadius": "5px"}}>{fieldArea} {unitDisplayName(this.props.userData.area)}</span>
                        </div>
                    </div>
                    {this.props.field_state === "active" && lastKC && !isNaN(lastKC) &&

                        <div className={"text-center flex-grow-1 " + paddingStyle} style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8"}}>
                            <div className={divsStyle}>{t('kc')} <span
                                className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                                style={{"borderRadius": "5px"}}>{lastKC}</span></div>
                        </div>
                    }

                    {this.props.field_state === "active" && lastET0Value &&
                        <div className={"text-center flex-grow-1 " + paddingStyle} style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8"}}>
                            <div className={divsStyle}>{t('et0')} <span
                                className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                                style={{"borderRadius": "5px"}}>{lastET0Value} {unitDisplayName(this.props.userData.length)}</span></div>
                        </div>
                    }


                    <div className={"text-center flex-grow-1 " + paddingStyle} style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8"}}>
                        <div className={divsStyle}>{t('soil_type')} <span
                            className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                            style={{"borderRadius": "5px"}}>{t(upper(soilType))}</span></div>
                    </div>

                    {accumulatedGDD &&
                        <div className={"text-center flex-grow-1 " + paddingStyle} style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8"}}>
                            <div className={divsStyle}>{t('gdd')} <span
                                className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                                style={{"borderRadius": "5px"}}>{accumulatedGDD} </span></div>
                        </div>
                    }
                    {leaf_size && leaf_size != "" &&
                        <div className={"text-center flex-grow-1 " + paddingStyle} style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8"}}>
                            <div className={divsStyle}>{t('plant_height')} <span
                                className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                                style={{"borderRadius": "5px"}}>{leaf_size} </span></div>
                        </div>
                    }
                    {fert_height && fert_height != "" &&
                        <div className={"text-center flex-grow-1 " + paddingStyle} style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8"}}>
                            <div className={divsStyle}>{t('fert_tank_height')} <span
                                className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                                style={{"borderRadius": "5px"}}>{fert_height} </span></div>
                        </div>
                    }
                    {fert_volume && fert_volume != "" &&
                        <div className={"text-center flex-grow-1 " + paddingStyle} style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8"}}>
                            <div className={divsStyle}>{t('Fert_tank_volume')} <span
                                className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                                style={{"borderRadius": "5px"}}>{fert_volume} </span></div>
                        </div>
                    }
                    {this.props.field_state === "active" && days_from_sowing !== undefined &&
                        <div className={"text-center flex-grow-1 " + paddingStyle}
                             style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8"}}>
                            <div className={divsStyle}>{t('days_from_sowing')} <span
                                className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                                style={{"borderRadius": "5px"}}>{days_from_sowing + " Days"}</span></div>
                        </div>
                    }
                    <div className={"text-center flex-grow-1 " + paddingStyle}
                         style={{"borderLeft": "0.5px solid #DCE0E8","borderBottom": "0.5px solid #DCE0E8", }}>
                        <div className={divsStyle}>{t('expiration_date')} <span
                            className={"p-1 pr-2 pl-2 ml-1 " + spanStyle}
                            style={{"borderRadius": "5px"}}>{expirationDate}</span></div>
                    </div>

                </div>

                {alert}
            </div>

        );
    }
};

const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data,
        selectedField: state.distribution_data.selected_entities.field,
        loadHistoryStatus: state.distribution_data.field_load_history,
        field_state: state.distribution_data.field_state,
        configuration_data: state.configuration_data,
        selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
        selectedDistribution: state.distribution_data.selected_entities.distribution,
    }
}

const mapDispatchToProps = {}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExtendedFieldDetails));
