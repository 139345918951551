const React = require('react');
const {useTranslation, withTranslation} = require("react-i18next");
const {useState, useEffect} = require("react");
const ContextMenu = require('ContextMenu');
const {SelectMenu} = require('SelectMenu');
const moment = require("moment");
const {connect, useSelector} = require("react-redux");
var DayPickerInput = require('./DayPickerInput');
const $ = require("jquery");
const {unitDisplayName} = require('unitsManager');


const weekDays = moment.weekdays();
let selectOptions = [];
Object.assign(selectOptions, weekDays.map((day) => {
    return {title: day, value: day.toLowerCase()}
}));
selectOptions.push({title: "Every Day", value: "everyDay"}, {
        title: "Every Two Days",
        value: "every2Day"
    }, {title: "Every Three Days", value: "every3Day"}, {
        title: "Every Four Days",
        value: "every4Day"
    }, {title: "Every Five Days", value: "every5Day"}, {
        title: "Every Six Days",
        value: "every6Day"
    }, {title: "Every Seven Days", value: "every7Day"}, {
        title: "Every Eight Days",
        value: "every8Day"
    }, {title: "Every Nine Days", value: "every9Day"}, {
        title: "Every Ten Days",
        value: "every10Day"
    },
    {title: "Every Two Days (Minimum)", value: "minEvery2Day"},
    {title: "Every Three Days (Minimum)", value: "minEvery3Day"},
    {title: "Every Four Days (Minimum)", value: "minEvery4Day"},
    {title: "Every Five Days (Minimum)", value: "minEvery5Day"},
    {title: "Every Six Days (Minimum)", value: "minEvery6Day"},
    {title: "Every Seven Days (Minimum)", value: "minEvery7Day"},
    {title: "Every Eight Days (Minimum)", value: "minEvery8Day"},
    {title: "Every Nine Days (Minimum)", value: "minEvery9Day"},
    {title: "Every Ten Days (Minimum)", value: "minEvery10Day"})

let hoursMenuOptions = [];

const setHoursMenuOptions = () => {
    let hours = [];
    for (let j = 0; j < 2; j++) {
        for (let i = 0; i < 48; i++) {
            let n = i % 2 == 0 ? i / 2 + ':00' : (i + 1) / 2 - 1 + ':30';
            if (i < 20) {
                n = '0' + n;
            }
            if (j) {
                if (i === 0) {
                    hours.push({title: "-" + "Next day" + "-", value: ""});
                }
                hours.push({title: n + "⁺¹", value: n + "+1"});
            } else {
                hours.push({title: n, value: n});
            }

        }
    }
    return hours;
}
hoursMenuOptions = setHoursMenuOptions();

const TimeFrame = ({
                       id,
                       day,
                       start_hour,
                       end_hour,
                       min,
                       minPlaceHolder,
                       minSource,
                       minTooltipContent,
                       max,
                       maxPlaceHolder,
                       maxSource,
                       maxTooltipContent,
                       name,
                       active_period_start_date,
                       active_period_end_date,
                       onDelete,
                       onChangeDay,
                       onChangeStartHour,
                       onChangeEndHour,
                       onChangeMin,
                       onChangeMax,
                       onChangeStartDate,
                       onChangeEndDate,
                       onChangeTimeFrameName,
                       displayDeleteMenu,
                       displayExpended,
                       timeFrameSerialNumber,
                       irrigationStartDate,
                   }) => {
    const {t} = useTranslation();
    const [showMenu, setShowMenu] = useState(false);
    const [showExpended, setShowExpended] = useState(displayExpended);
    const [endHourMenuOptions, setEndHourMenuOptions] = useState(hoursMenuOptions);
    const [dayChangeError, setDayChangeError] = useState(false);
    const [startHourChangeError, setStartHourChangeError] = useState(false);
    const [endHouChangeError, setEndHourChangeError] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [minSourceState, setMinSourceState] = useState(minSource);
    const [maxSourceState, setMaxSourceState] = useState(maxSource);
    const userData = useSelector((state) => state.authentication_data.user_data);
    let userLengthUnits = (userData && ("length" in userData)) ? unitDisplayName(userData.length) : "";

    useEffect(() => {
        calculateFilteredHoursOptions(start_hour);
    }, []);

    useEffect(() => {

        return () => {
            // clean state when moving to another fields , update modal is one
            setDayChangeError(false);
            setStartHourChangeError(false);
            setEndHourChangeError(false);
        }
    }, [onChangeDay, onChangeStartHour, onChangeEndHour])

    const calculateFilteredHoursOptions = (hour) => {
        var hours = [];
        var chosenStartHourIndex = hoursMenuOptions.findIndex(obj => obj.value === hour);
        for (var i = chosenStartHourIndex + 1; i < 48; i++) {
            var n = i % 2 == 0 ? i / 2 + ':00' : (i + 1) / 2 - 1 + ':30';
            if (i < 20) {
                n = '0' + n;
            }
            hours.push({title: n, value: n});
        }
        hours.push({title: "00:00", value: "00:00"});
        // setEndHourMenuOptions(hours);
    }

    const handleChangeStartHour = (hour) => {
        calculateFilteredHoursOptions(hour);
        if (onChangeStartHour(id, hour)) {
            setStartHourChangeError(true);

        } else {
            calculateFilteredHoursOptions(hour);
            setStartHourChangeError(false);
        }
    }
    const handleChangeDay = (id, newDay) => {
        if (onChangeDay(id, newDay)) {
            setDayChangeError(true);
        } else setDayChangeError(false);

    }

    const handleChangeEndHour = (id, newEndHour) => {
        if (onChangeEndHour(id, newEndHour)) {
            setEndHourChangeError(true);
        } else setEndHourChangeError(false);
    }

    const handleChangeMin = (id, newMin) => {
        onChangeMin(id, newMin);
        if (newMin !== "") {
            setMinSourceState(t("edit_field_irrigation_timeframe_source_user"));
        } else {
            setMinSourceState(t("edit_field_irrigation_timeframe_source_soil"));
        }
    }

    const handleChangeMax = (id, newMax) => {
        onChangeMax(id, newMax);
        if (newMax !== "") {
            setMaxSourceState(t("edit_field_irrigation_timeframe_source_user"));
        } else {
            if (maxTooltipContent.includes("rate")) {
                setMaxSourceState(t("edit_field_irrigation_timeframe_source_irrigation_rate"));
            } else {
                setMaxSourceState(t("edit_field_irrigation_timeframe_source_soil"));
            }
        }
    }

    const handleChangeStartDate = (newStartDate) => {
        if (newStartDate === undefined) {
            onChangeStartDate(id, "");
            setStartDate("");
        } else {
            let formattedStartDate = moment(newStartDate).format('YYYY-MM-DD');
            onChangeStartDate(id, formattedStartDate);
            setStartDate(formattedStartDate);
        }
    }

    const handleChangeEndDate = (newEndDate) => {
        if (newEndDate === undefined) {
            onChangeEndDate(id, "");
            setEndDate("");
        }
        else if ((startDate === "" || moment(startDate).isBefore(moment(newEndDate)))) {
            let formattedEndDate = moment(newEndDate).format('YYYY-MM-DD');
            onChangeEndDate(id, formattedEndDate);
            setEndDate(formattedEndDate);
        } else {
            let formattedEndDate = moment(moment(startDate).add(1, 'days').format('YYYY-MM-DD'));
            onChangeEndDate(id, formattedEndDate);
            setEndDate(formattedEndDate);
        }
    }

    const handleChangeTimeFrameName = (id, newName) => {
        onChangeTimeFrameName(id, newName);
    }


    const menuOptions = [
        {
            title: 'Delete',
            icon: require("images/icons/trash.svg"),
            func: onDelete,
            textColor:"#F66B7A"
        }
    ]
    let openImgSrc = require('images/icons/open_arrow_grey71.svg');
    let closeImgSrc = require('images/icons/close_arrow_grey71.svg');

    return (
        <div id={"timeframe"} style={{marginBottom: "28px"}}>
            <div className="d-flex flex-row card border-0 bg-grey98 col-12 p-0"
                 style={{justifyContent: "space-evenly", marginLeft: "8px"}}>
                <div className={"col-3 p-0"} style={{display: "flex", flexDirection: "row"}}>
                    <div style={{
                        marginLeft: "2px",
                        marginRight: "2px",
                        display: "flex",
                        width: "24px",
                        paddingTop: "30px"
                    }}>
                        {!displayExpended && <div onClick={() => {
                            setShowExpended(!showExpended)
                        }} className="timeFrameOpenBtn d-flex justify-content-center align-items-center"
                                                  style={{width: "24px", height: "24px", marginTop: "4px"}}>
                            <img src={showExpended ? openImgSrc : closeImgSrc}/>
                        </div>}
                    </div>

                    <div style={{marginRight: "32px", flexDirection: "column"}}>
                        <label style={{marginBottom: 4}}
                               className='text-grey45 text-style-titles-14-semi-bold'>{"Timeframe name"}</label>
                        <input className="form-control" type="text"
                               defaultValue={name || "Timeframe " + timeFrameSerialNumber} style={{height: "40px"}}
                               onChange={(e) => {
                                   handleChangeTimeFrameName(id, e.target.value)
                               }}/>
                    </div>
                </div>
                <div className='col-4 p-0'>
                    <div style={{flexDirection: "column"}}>
                        <label style={{marginBottom: 4}}
                               className='text-style-titles-14-semi-bold text-grey45'>{"Day"}</label>
                        <SelectMenu options={selectOptions} dayError={dayChangeError}
                                    curValue={day}
                                    onChange={(newDay) => handleChangeDay(id, newDay)} sortBox={''}
                                    menuStyle={{height: "40px"}}/></div>
                    <label hidden={!dayChangeError}
                           className='mb-0 text-size-12 lh-1 text-red96'>{t("select_different_day")}</label>
                </div>
                <div className={"col-4 p-0"} style={{display: "flex", flexDirection: "row"}}>
                    <div className='flex-column w-100'>
                        <label style={{marginBottom: 4}}
                               className='text-style-titles-14-semi-bold text-grey45'>{t("start_hour")}</label>
                        <div><SelectMenu options={hoursMenuOptions}
                                         startHourError={startHourChangeError} curValue={start_hour}
                                         onChange={(newStartHour) => handleChangeStartHour(newStartHour)} sortBox={''}
                                         menuStyle={{height: "40px"}}/>
                        </div>
                        <label hidden={!startHourChangeError}
                               className='mb-0 text-size-12 lh-1 text-red96'>{t("select_different_hour")}</label>
                    </div>
                    <div className='flex-column w-100' style={{marginLeft: "8px"}}>
                        <label style={{marginBottom: 4}}
                               className='text-style-titles-14-semi-bold text-grey45'>{t("end_hour")}</label>
                        <div><SelectMenu options={endHourMenuOptions}
                                         endHourError={endHouChangeError}
                                         curValue={end_hour}
                                         onChange={(newEndHour) => handleChangeEndHour(id, newEndHour)} sortBox={''}
                                         menuStyle={{height: "40px"}}/>
                        </div>
                        <label hidden={!endHouChangeError}
                               className='mb-0 text-size-12 lh-1 text-red96'>{t("select_different_hour")}</label>
                    </div>
                </div>
                {displayDeleteMenu ? <div className='d-flex align-items-center justify-content-center position-relative'
                                          style={{paddingTop: "28px"}}>
                    {showMenu && <ContextMenu options={menuOptions} onToggle={setShowMenu} isDeleteMenu={true}/>}
                    <button onClick={(e) => { setShowMenu(!showMenu); e.stopPropagation() }} type='button'
                            className={`d-flex btn justify-content-center align-items-center sensorMenuBtn ${showMenu ? 'active' : ''}`}>
                        <img src={require("images/icons/three-dots-horizontal.svg")}/>
                    </button>
                </div> : <div className='d-flex align-items-center justify-content-center position-relative'
                              style={{width: "32px"}}/>}
            </div>
            {showExpended &&
                <div>
                    <div className="d-flex flex-row card border-0 bg-grey98 col-12 p-0"
                         style={{marginTop: "24px", justifyContent: "space-evenly", marginLeft: "8px"}}>
                        <div className={"col-3 p-0"} style={{display: "flex", flexDirection: "row"}}/>
                        <div className='col-4 p-0'>
                            <div className={"d-flex flex-column"}>
                                <label style={{marginBottom: 4}}
                                       className='text-style-titles-14-semi-bold text-grey45'>{t("start_date")}</label>

                                <DayPickerInput
                                  value={active_period_start_date}
                                  onChange={handleChangeStartDate}
                                  dayPickerProps={{
                                      isValidDate: currentDate => {
                                          return (irrigationStartDate ? currentDate >= moment(irrigationStartDate) : true) && (endDate ? currentDate <= moment(endDate) : true)
                                      }
                                  }}
                                  inputProps={{
                                      className: "placeholder",
                                      id: "start_date_input",
                                      placeholder: "Select...",
                                      style: {width: '100%', height: "40px"}
                                  }}
                                />
                            </div>
                            <label hidden={!dayChangeError}
                                   className='mb-0 text-size-12 lh-1 text-red96'>{t("select_different_day")}</label>
                        </div>
                        <div className='d-flex col-4 p-0 flex-column'>
                            <label style={{marginBottom: 4}}
                                   className='text-style-titles-14-semi-bold text-grey45'>{t("end_date")}</label>
                            <DayPickerInput
                              value={active_period_end_date}
                              onChange={handleChangeEndDate}
                              dayPickerProps={{
                                  isValidDate: currentDate => {
                                      return startDate ? currentDate >= moment(startDate) : true
                                  }
                              }}
                              inputProps={{
                                  className: "placeholder",
                                  id: "end_date_input",
                                  placeholder: active_period_start_date ? t("Timeframe_end_of_season") : "Select...",
                                  style: {width: '100%', height: "40px"}
                              }}
                            />
                            <label hidden={!startHourChangeError}
                                   className='mb-0 text-size-12 lh-1 text-red96'>{t("select_different_day")}</label>
                        </div>
                        <div className='d-flex align-items-center justify-content-center position-relative'
                             style={{width: "32px"}}/>

                    </div>
                    <div className="d-flex flex-row card border-0 bg-grey98 col-12 p-0"
                         style={{marginTop: "24px", justifyContent: "space-evenly", marginLeft: "8px"}}>
                        <div className={"col-3 p-0"} style={{display: "flex", flexDirection: "row"}}/>
                        <div className={"col-4 p-0"} id={"tooltip_container_min"}
                             style={{display: "flex", flexDirection: "row"}}>
                            <div className='flex-column'>
                                <label style={{marginBottom: 4}}
                                       className='text-style-titles-14-semi-bold text-grey45'>{`${t("minimum")} (${userLengthUnits})`}</label>
                                <div><input className="form-control placeholder" type={"number"} value={min} min={"0"}
                                            placeholder={minPlaceHolder} style={{width: "80px", height: "40px"}}
                                            onKeyPress={(event) => {
                                                if (event.charCode < 48) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                handleChangeMin(id, e.target.value)
                                            }}/>
                                </div>
                            </div>
                            <div className={"flex-column d-flex justify-content-end"} style={{marginLeft: "8px"}}>
                                <div style={{marginBottom: 2}}
                                     className='text-size-14 text-grey45 lh-1 font-weight-normal'>{"Source:"}</div>
                                <label
                                    data-toggle="tooltip" id={min === null ? "" : "tooltipMin"}
                                    onMouseOver={() => {
                                        $("#tooltipMin").tooltip('show')
                                    }}
                                    data-placement="bottom"
                                    data-container="#tooltip_container_min"
                                    title={minTooltipContent}
                                    style={{
                                        backgroundColor: "#EEF1F6",
                                        borderRadius: "3px",
                                        marginBottom: "0px",
                                        padding: "2px 4px"
                                    }} className='text-style-titles-14-semi-bold text-grey45'>{minSourceState}</label>
                            </div>
                        </div>
                        <div className={"col-4 p-0"} id={"tooltip_container_max"}
                             style={{display: "flex", flexDirection: "row"}}>
                            <div className='flex-column'>
                                <label style={{marginBottom: 4}}
                                       className='text-style-titles-14-semi-bold text-grey45 lh-1'>{`${t("maximum")} (${userLengthUnits})`}</label>
                                <div><input className="form-control placeholder" type={"number"} value={max}
                                            placeholder={maxPlaceHolder} min={"0"} onKeyPress={(event) => {
                                                if (event.charCode < 48) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                handleChangeMax(id, e.target.value)
                                            }} style={{width: "80px", height: "40px"}}/>
                                </div>
                            </div>
                            <div className={"flex-column d-flex justify-content-end"} style={{marginLeft: "8px"}}>
                                <div style={{marginBottom: 2}}
                                     className='text-size-14 text-grey45 lh-1 font-weight-normal'>{"Source:"}</div>
                                <label id={max === null ? "" : `tooltipMax`} style={{
                                    backgroundColor: "#EEF1F6",
                                    borderRadius: "3px",
                                    marginBottom: "0px",
                                    padding: "2px 4px"
                                }} className='text-style-titles-14-semi-bold text-grey45'
                                       data-toggle="tooltip"
                                       onMouseOver={() => {
                                           $(`#tooltipMax`).tooltip('show')
                                       }}
                                       data-placement="bottom"
                                       data-container="#tooltip_container_max"
                                       title={maxTooltipContent}>{maxSourceState}</label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-center position-relative'
                             style={{width: "32px"}}/>

                    </div>
                </div>
            }
        </div>
    );
};


const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data,
    }
}
const mapDispatchToProps = {}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimeFrame));